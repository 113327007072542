body {
  background-color: #000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --face: #fba81a;
  --lowlight: #aaa;
  --side: #896c3b;
  --side-dark: #black;
  --coin-size: 2rem;
  --coin-face:   url('https://cdn.sanity.io/images/94xksvt2/production/3a81d9e715de32bca8e9e8538ba1985160bf2461-500x500.png?w=500&h=500&auto=format');
}

.box {
  background-color: black;
  color: white;
  font-family: Arial;
  font-weight: bold;
  float: left;
}

.first {
	font-size: 0.8em;
}

.smallbox {
  width: 1em;
  font-size: 9px;
  width: 0.5em;
  word-wrap: break-word;  
  margin: 1px;
  padding: 1px;  
}
.million{
 border: 3px solid green;
}
.thousand{
 border: 3px solid blue;
}
.hundred{
 border: 3px solid purple;
}
.billion{
  border: 3px solid #aaa;
}
.primary{
  font-size: 1em;
}

.kwei{
 border: 3px solid yellow;
}

.mwei{
 border: 3px solid #FFBF00;
}

.gwei{
 border: 3px solid #FF5733;
}

.microether{
 border: 3px solid #C70039;
}

.ether{
 border: 3px solid red;
}

.mether{
 border: 3px solid #581845;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
div.box:hover{
 font-size: 35px;
}

.coin-left {
  float: left;
}
.coin-right {
  float: right;
}

.coin {
  float: left;
  height: var(--coin-size);
  width: var(--coin-size);
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coin::before {
  content: '';
  display: block;
  position: relative;
  height: var(--coin-size);
  width: var(--coin-size);
  border-radius: 50%;
  background-color: var(--face);
  animation: spin 1s linear infinite;
  background-image: var(--coin-face);
  background-size: 100% 100%;
  background-position: center;
  background-blend-mode: overlay;
}



/* animation definitions */

@keyframes spin {
  0% {
    width: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
    animation-timing-function: ease-in;
  }
  
  49.999% {
    width: 0.1rem;
    box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: linear;
  }
  
  50.001% {
    width: 0.1rem;
    box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    transform: translateX(0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: ease-out;
  }
  
  100% {
    width: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
  }
}

